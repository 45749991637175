import React from 'react';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home">
      <h1>Bienvenue sur mon Portfolio</h1>
      <p>
        Développeur web passionné avec un parcours riche en apprentissages, je
        combine expertise en réseaux et compétences en développement pour
        créer des solutions innovantes adaptées à vos besoins.
      </p>
      <p>
        Découvrez mon parcours, mes compétences et les projets sur lesquels j'ai
        travaillé au fil des années.
      </p>
    </div>
  );
}

export default Home;

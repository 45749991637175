import React, { useState } from 'react';
import '../styles/Navigation.css';

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="logo">Mon Portfolio</div>
      <button className="burger-menu" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          <a href="#home" onClick={closeMenu}>
            Accueil
          </a>
        </li>
        <li>
          <a href="#about" onClick={closeMenu}>
            À propos
          </a>
        </li>
        <li>
          <a href="#projects" onClick={closeMenu}>
            Mes Projets
          </a>
        </li>
        <li>
          <a href="#contact" onClick={closeMenu}>
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;

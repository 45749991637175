import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer style={{
      textAlign: 'center',
      padding: '20px',
      backgroundColor: '#333',
      color: 'white',
      position: 'relative',
      bottom: '0',
      width: '100%',
      marginTop: '20px',
      borderTop: '1px solid #555'
    }}>
      © 2024 Nicolas Gayet - Tous droits réservés
    </footer>
  );
}

export default Footer;

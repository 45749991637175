import React from 'react';
import { projects } from '../data/projectsData';
import '../styles/Projects.css';

function Projects() {
  return (
    <div className="projects">
      <h2>Mes Projets</h2>
      <ul>
        {projects.map((project) => (
          <li key={project.id}>
            <img src={project.image} alt={project.title} style={{ width: '100%', borderRadius: '8px' }} />
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              Voir le projet
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Projects;